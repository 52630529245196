<template lang="pug">
include ../../../../configs/template
form.row.align-end
  div.col-sm-12.col-md-6.col-xl-4
    +field-validation('body.additional_info.number', 'number', '["required"]')(:readonly="isCheckDocument" :loading="isLoadingGetInfoPhoto")
  div.col-sm-12.col-md-6.col-xl-4
    +date-picker-validation('body.additional_info.date_start', 'dateIssue', 'dateStartObject', '["required", "minValue", "maxValue"]', 'isCheckDocument', '!isCheckDocument')(
      :max="new Date().toISOString()"
      min="1900-01-01"
      :locale="lang"
      :readonly="isCheckDocument"
      :loading="isLoadingGetInfoPhoto")
  div.col-sm-12.col-md-6.col-xl-4
    +date-picker-validation('body.additional_info.date_end', 'dateTermination', 'dateEndObject', '["required", "minValue"]', 'isCheckDocument', '!isCheckDocument')(
      max="2200-12-31"
      :min="body.additional_info.date_start || new Date().toISOString()"
      :locale="lang"
      :readonly="isCheckDocument"
      :loading="isLoadingGetInfoPhoto")
  div(v-if="isCheckDocument").col-sm-12.col-md-6.col-xl-4
    +field-validation('body.additional_info.last_name_ukr','lastNameUK','["required", "alphaUA"]')(
      onkeypress="return /[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ\s-']/i.test(event.key)"
    )
  div(v-if="isCheckDocument").col-sm-12.col-md-6.col-xl-4
    +field-validation('body.additional_info.first_name_ukr','lastNameUK','["required", "alphaUA"]')(
      onkeypress="return /[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ\s-']/i.test(event.key)"
    )
  div(v-if="isCheckDocument").col-sm-12.col-md-6.col-xl-4
    +field-validation('body.additional_info.middle_name_ukr','middleNameUK','["alphaUA"]')(
      onkeypress="return /[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ\s-']/i.test(event.key)"
    )
  div(v-if="isCheckDocument").col-sm-12.col-md-6.col-xl-6
    +field-validation('body.additional_info.last_name_eng','lastNameEN','["required", "alphaEN"]')(
      onkeypress="return /[a-zA-Z\s-']/i.test(event.key)"
      :loading="isLoadingGetInfoPhoto"
    )
  div(v-if="isCheckDocument").col-sm-12.col-md-6.col-xl-6
    +field-validation('body.additional_info.first_name_eng','firstNameEN','["required", "alphaEN"]')(
      onkeypress="return /[a-zA-Z\s-']/i.test(event.key)"
      :loading="isLoadingGetInfoPhoto"
    )
  div(v-if="isCheckDocument").col-12
    +select('body.additional_info.country','listCountries','country','valueLang')
  div(v-if="isCheckDocument").col-12
    FileDropZone(ref="mediaContent")
  div.col-12
    v-btn(v-if="isCheckDocument" color="success" @click="saveDocument" :loading="isLoadingSave").mr-4 {{$t('save')}}
    v-btn(v-else color="success" @click="checkDocument" :loading="isLoading") {{$t('btn.checkDocument')}}
    v-btn(v-if="isCheckDocument" color="secondary" @click="clearForm") {{$t('btn.clear')}}
</template>

<script>
import { verificationSC } from '@/mixins/validationRules'
import { mapActions, mapState } from 'vuex'
import { clearBody, getFileAll, ucFirst } from '@/mixins/main'

const initBody = () => (
  {
    additional_info: {
      number: null,
      date_start: null,
      date_end: null,
      last_name_ukr: null,
      first_name_ukr: null,
      middle_name_ukr: '',
      last_name_eng: null,
      first_name_eng: null
    }
  }
)
export default {
  name: 'ForeignPassport-digitization',
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue')
  },
  props: {
    photos: { type: Array, default: () => [] }
  },
  data () {
    return {
      body: initBody(),
      isLoading: false,
      isCheckDocument: false,
      isLoadingGetInfoPhoto: false
    }
  },
  validations () { return verificationSC.foreignPassport(this) },
  computed: {
    ...mapState({
      lang: state => state.main.lang,
      valueLang: state => state.main.lang === 'en' ? 'value_eng' : 'value',
      listCountries: state => state.directory.country,
      isLoadingSave: state => state.documents.isLoading
    }),
    dateStartObject () {
      return this.body.additional_info.date_start ? new Date(this.body.additional_info.date_start) : null
    },
    dateEndObject () {
      return this.body.additional_info.date_end ? new Date(this.body.additional_info.date_end) : null
    }
  },
  mounted () {
    this.checkForPhoto()
  },
  methods: {
    ...mapActions(['getInfoAboutDocumentForDigitizationSC', 'getInfoCheckForPhoto']),
    async checkForPhoto () {
      if (this.sailorDocument) {
        this.isLoadingGetInfoPhoto = true
        this.photoArray = await getFileAll(this.sailorDocument.photo)
        if (this.photoArray.length && this.photoArray[0] !== undefined) {
          const response = await this.getInfoCheckForPhoto(this.photoArray)
          for (let key in response) {
            this.body.additional_info[`${key}_eng`] = await ucFirst(response[key]?.toLowerCase())
            key === 'serial_number' && (this.body.additional_info.number = response[key])
            key === 'date_end' && (this.body.additional_info[key] = response[key])
          }
          this.isLoadingGetInfoPhoto = false
        }
      }
      this.isLoadingGetInfoPhoto = false
    },

    saveDocument () {
      const { statementId } = this.$route.params
      if (this.$v.$invalid) return this.$v.$touch()
      let data = {
        body: { ...this.body },
        media: {
          name: 'ForeignPassport',
          files: [...this.$refs.mediaContent.filesArray]
        }
      }
      this.$emit('saveDocument', { id: statementId, data, method: 'setDigitizationDocumentSC' })
    },
    async checkDocument () {
      if (this.$v.$invalid) return this.$v.$touch()
      this.isLoading = true
      const { statementId } = this.$route.params
      const response = await this.getInfoAboutDocumentForDigitizationSC({ id: statementId, body: { additional_info: { ...clearBody(this.body.additional_info) } } })
      if (response) {
        this.$notification.success('notify.success.checkDocument')
        this.isCheckDocument = true
      }
      this.isLoading = false
    },
    clearForm () {
      this.isCheckDocument = false
      this.body = initBody()
      this.$v.$reset()
    }
  }
}
</script>
